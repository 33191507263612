<template>
    <el-drawer
            size="45%"
            title="权限设置"
            :visible.sync="visible"
            direction="rtl"
            :before-close="handleSendClose"
    >
        <el-divider></el-divider>
        <el-tree
                style="height:600px;overflow:auto"
                :data="data"
                show-checkbox
                default-expand-all
                node-key="id"
                ref="tree"
                highlight-current
                :props="defaultProps">
            <span class="custom-tree-node" slot-scope="{ data }">
                <span>{{ data.name }}</span>
                <span>
                    <el-tag style="margin-right: 10px;" :type="tags[data.type].type" size="mini">
                        {{tags[data.type].name}}
                    </el-tag>
                </span>
            </span>
        </el-tree>
        <el-divider></el-divider>
        <el-row style="margin: 10px">
            <el-button @click="submit" type="primary">确认</el-button>
            <el-button @click="handleSendClose">取消</el-button>
        </el-row>
    </el-drawer>
</template>
<script>
    export default {
        props: {
            row: Object,
            visible: Boolean,
        },
        data() {
            return {
                data: [],
                defaultProps: {
                    children: 'children',
                    label: 'name',
                },
                tags: [
                    {name: '模块', type: ''},
                    {name: '菜单', type: 'success'},
                    {name: '接口', type: 'info'},
                    {name: '按钮', type: 'warning'}
                ],
            };
        },
        methods: {
            async listData() {
                //清空选择
                if (this.data.length>0){
                    this.resetChecked();
                }
                let res = await this.$post(this.$api.powerList, {});
                this.data = res.data;
                if (this.data.length > 0){
                    await this.setRolePowerDisabled();
                    this.setSelfPower();
                }
            },
            handleSendClose() {
                this.$emit("dialog", false);
            },
            submit() {
                let checkedNodes = this.getCheckedNodes();
                let selfPowerIds = checkedNodes.filter((item) => {return (!item.disabled && item.children.length === 0);}).map(data => data.id);
                this.$post(this.$api.adminPowerReSet, {"adminId": this.row.id, "powerIds": selfPowerIds}).then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            type: "success",
                            message: "成功!",
                        });
                        this.$parent.listData();
                        this.handleSendClose();
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                        });
                    }
                });
            },
            getCheckedKeys() {
                return this.$refs.tree.getCheckedKeys();
            },
            setCheckedKeys(array) {
                this.$refs.tree.setCheckedKeys(array);
            },
            setCheckedNodes(array) {
                let nodes = [];
                array.forEach(item => {
                   nodes.push({id: item});
                });
                this.$refs.tree.setCheckedNodes(nodes);
            },
            getCheckedNodes() {
                return this.$refs.tree.getCheckedNodes();
            },
            resetChecked() {
                this.$refs.tree.setCheckedKeys([]);
            },
            async setRolePowerDisabled(){
                const res = await this.$post(this.$api.adminRolePowerIds, {"id": this.row.id});
                const {data} = res;
                if (data.length > 0){
                    this.setCheckedKeys(data);
                    this.getCheckedNodes().forEach(item => {
                        item.disabled = true;
                    });
                }
            },
            async setSelfPower(){
                const res = await this.$post(this.$api.adminSelfPowerIds, {"id": this.row.id});
                const {data} = res;
                if (data.length > 0){
                    this.setCheckedKeys([...this.getCheckedKeys(),...data]);
                }
            }
        },
        created() {
        },
        computed: {},
        watch: {
            visible() {
                if (this.visible) {
                  this.listData();
                }
            },
        }
    };
</script>
<style>
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }
</style>