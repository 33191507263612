<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogSendVisible"
    width="50%"
    :before-close="handleSendClose"
  >
    <el-timeline>
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="span">
            <el-form-item
              label="账号"
              prop="account"
              :rules="[
                { required: true, message: '请输入账号', trigger: 'blur' },
              ]"
            >
              <el-input
                v-model.number="ruleForm.account"
                clearable
                :disabled="disabled"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="span">
            <el-form-item label="密码" prop="password">
              <el-input
                type="password"
                v-model="ruleForm.password"
                autocomplete="off"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="span">
            <el-form-item label="确认密码" prop="check_password">
              <el-input
                type="password"
                v-model="ruleForm.check_password"
                autocomplete="off"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="span">
            <el-form-item
              label="手机"
              prop="mobile"
              :rules="[
                { required: true, message: '请输入手机号码', trigger: 'blur' }
              ]"
            >
              <el-input
                v-model.number="ruleForm.mobile"
                clearable
                :disabled="disabled"
                maxlength="11"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="span">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="ruleForm.email" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="span"  v-if="!disabled && rolesData.length > 0">
            <el-form-item
              label="角色"
              prop="roleId"
              :rules="[
                { required: true, message: '请选择角色', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="ruleForm.roleId"
                placeholder="请选择"
                clearable
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  v-for="(item, k) in rolesData"
                  :label="item.roleName"
                  :value="item.id"
                  :key="k"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="span">
            <el-form-item label="状态" prop="state">
              <el-switch
                v-model="ruleForm.state"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="span"></el-col>
          <el-col :span="24">
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')"
                >提交</el-button
              >
              <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-timeline>
  </el-dialog>
</template>
<script>
export default {
  props: {
    row: Object,
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.check_password !== "") {
          this.$refs.ruleForm.validateField("check_password");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    const checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!value) {
        callback();
      }else{
        setTimeout(() => {
          if (mailReg.test(value)) {
            callback();
          } else {
            callback(new Error("请输入正确的邮箱格式"));
          }
        }, 100);
      }
    };
    return {
      span: 12,
      title: "新增",
      dialogSendVisible: true,
      rolesData: "",
      disabled: false,
      ruleForm: {
        password: "",
        check_password: "",
        account: "",
        email: "",
        mobile: "",
        roleId: "",
        state: 1,
      },
      rules: {
        password: [
          { validator: validatePass, trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
        ],
        check_password: [
          { validator: validatePass2, trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
        ],
        email: [{ validator: checkEmail, trigger: "blur" }],
      },
      activeName: 'first',
      activeNameOfPower:'third',
      adminRoles:[]
    };
  },
  methods: {
    handleSendClose() {
      this.dialogSendVisible = false;
      this.$emit("dialog", false);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.post(this.$api.adminSet, {
              account: this.ruleForm.account,
              check_password: this.ruleForm.check_password,
              email: this.ruleForm.email,
              id: this.row.id,
              mobile: this.ruleForm.mobile,
              password: this.ruleForm.password,
              roleId: this.ruleForm.roleId,
              state: this.ruleForm.state,
          });
        } else {
          return false;
        }
      });
    },
    post(url, data) {
      this.$post(url, data).then((res) => {
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          this.$parent.listData();
          this.handleSendClose();
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    roles() {
        this.$post(this.$api.roleList, {"obj":{}}).then((res) => {
            if (res.code === 1) {
                this.rolesData = res.data.records;
            }
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  created() {
    if (this.row && this.row.id) {
      this.title = "编辑";
      this.ruleForm = this.row;
      this.disabled = true;
    }
    this.roles();
  }
};
</script>
