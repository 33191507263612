<template>
    <el-drawer
            size="45%"
            title="添加角色"
            :visible.sync="visible"
            direction="rtl"
            :before-close="handleSendClose"
    >
        <el-divider></el-divider>
        <el-form label-position="top" label-width="80px" :model="ruleForm" ref="ruleForm" style="margin: 10px">
            <el-form-item label="用户" prop="selectUserIds"
                          :rules="[{ required: true, message: '请选择用户', trigger: 'change'}]">
                <el-select style="width: 100%" filterable
                           remote v-model="ruleForm.selectUserIds" multiple placeholder="请选择" disabled>
                    <el-option
                            v-for="item in users"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="角色" prop="selectRoleIds"
                          :rules="[{ required: true, message: '请选择角色', trigger: 'blur'}]">
                <el-checkbox-group v-model="ruleForm.selectRoleIds">
                    <el-checkbox v-for="role in roleList" :label="role.key" :key="role.key" :disabled="role.disabled">
                        {{role.label}}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <el-row style="margin: 10px">
            <el-button @click="submitForm('ruleForm')" type="primary">确认</el-button>
            <el-button @click="handleSendClose">取消</el-button>
        </el-row>
    </el-drawer>
</template>
<script>
    export default {
        props: {
            users: Array,
            userIds: Array,
            visible: Boolean
        },
        data() {
            return {
                ruleForm: {
                    selectUserIds: [],
                    selectRoleIds: [],
                },
                roleList: [],
            };
        },
        methods: {
            handleSendClose() {
                this.$emit("dialog", false);
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$post(this.$api.adminRoleSet,
                            {"adminIds": this.ruleForm.selectUserIds, "roleIds": this.ruleForm.selectRoleIds}
                        ).then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    type: "success",
                                    message: "成功!",
                                });
                                this.$parent.listData();
                                this.handleSendClose();
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.msg,
                                });
                            }
                        });
                    } else {
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            clearValidate(formName) {
                this.$nextTick(() => {
                    this.$refs[formName].clearValidate();
                })
            },
            roleDisabled() {
                this.$post(this.$api.adminRoleIds, {"adminIds": this.ruleForm.selectUserIds}
                ).then((res) => {
                    if (res.data) {
                        this.ruleForm.selectRoleIds = res.data;
                        if (this.ruleForm.selectUserIds.length > 1) {
                            for (let name of res.data) {
                                for (let role of this.roleList) {
                                    if (name === role.key) {
                                        role.disabled = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                });
            }
        },
        created() {
        },
        computed: {},
        watch: {
            visible() {
                if (this.visible) {
                    this.ruleForm.selectRoleIds = [];
                    this.roleList = [];
                    this.clearValidate("ruleForm");
                    //更新角色列表
                    this.$post(this.$api.roleList, {"obj": {}}).then((res) => {
                        if (res.data.records) {
                            res.data.records.forEach((item) => {
                                this.roleList.push({
                                    label: item.roleName,
                                    key: item.id
                                });
                            });
                        }
                        this.roleDisabled();
                    });
                }
            },
            userIds() {
                this.ruleForm.selectUserIds = this.userIds;
            }
        }
    };
</script>
