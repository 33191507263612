<template>
  <div class="app">
    <el-dialog
      title="敏感操作，请验证口令"
      :visible.sync="dialogSendVisible"
      width="30%"
      :before-close="handleSendClose"
    >
      <el-timeline>
        <el-form
          :inline="true"
          ref="form"
          :model="form"
          class="demo-form-inline"
        >
          <el-form-item
            label="操作密码"
            prop="pwd"
            :rules="[
              { required: true, message: '请输入操作密码', trigger: 'blur' },
              {
                min: 2,
                max: 20,
                message: '长度在 2 到 20 个字符',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="form.pwd"
              placeholder="请输入操作密码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit('form')">确定</el-button>
          </el-form-item>
        </el-form>
      </el-timeline>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    row: Object,
  },
  data() {
    return {
      dialogSendVisible: false,
      form: {
        pwd: "",
      },
    };
  },
  methods: {
    handleSendClose() {
      this.dialogSendVisible = false;
      this.$emit("dialog", false);
    },
    onSubmit(formName) {
      let postData = {
        id: this.row.id,
      };
      if (this.form.pwd !== this.hrwjPassword) {
        this.$message({
          type: "error",
          message: "输入密码错误！",
        });
        return false;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.post(this.$api.adminDel, postData);
        } else {
          this.$message({
            type: "warning",
            message: "请填写完整信息！",
          });
          return false;
        }
      });
    },
    post(url, data) {
      this.$post(url, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          this.$parent.listData();
          this.handleSendClose();
          // this.dialogSendVisible = false;
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
  },
  created() {
    this.dialogSendVisible = true;
  },
};
</script>
<style lang="scss" scoped>
</style>
