<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>系统</el-breadcrumb-item>
          <el-breadcrumb-item>权限管理</el-breadcrumb-item>
          <el-breadcrumb-item>管理员列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <div class="header-form">
        <div class="admin_main_block_left">
          <el-form>
            <el-form-item label="角色">
              <el-select v-model="where.obj.roleId" placeholder="请选择" clearable>
                <el-option label="全部" value=""></el-option>
                <el-option
                  v-for="(item, k) in rolesData"
                  :label="item.roleName"
                  :value="item.id"
                  :key="k"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="账号">
              <el-input
                v-model="where.obj.account"
                placeholder="账号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="手机">
              <el-input
                v-model="where.obj.mobile"
                placeholder="请输入手机"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input
                v-model="where.obj.email"
                placeholder="请输入邮箱"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" plain icon="el-icon-search" @click="listData()"
                >条件筛选</el-button
              >
            </el-form-item>
          </el-form>
        </div>

        <div class="admin_main_block_right">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-button
                type="primary"
                @click="showDailog({})"
                icon="el-icon-plus"
                >新增</el-button
              >
            </el-col>
            <el-col :span="12">
              <el-button
                type="primary"
                @click="exportData"
                disabled
                icon="el-icon-tickets"
                >导出数据</el-button
              >
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="admin_table_main">
        <el-table
          :data="list"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.1)"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
                  type="selection"
                  width="55">
          </el-table-column>
          <el-table-column prop="id" label="ID"></el-table-column>
          <el-table-column prop="account" label="用户名">
            <template slot-scope="scope">
              <el-link type="primary" @click="showDailog(scope.row)">{{scope.row.account}}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="mobile" label="手机"></el-table-column>
          <el-table-column prop="email" label="邮箱"></el-table-column>
          <el-table-column prop="role" label="角色">
            <template slot-scope="scope">
              {{scope.row.role?scope.row.role:'/'}}
            </template>
          </el-table-column>
          <el-table-column prop="createDate" label="加入时间"></el-table-column>
          <el-table-column prop="deviceType" label="状态">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.state"
                @change="setState(scope.row)"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="270px">
            <template slot-scope="scope">
              <el-link style="margin-left: 10px" type="primary" @click="addRoleAction(scope.row)">角色设置</el-link>
              <el-link style="margin-left: 10px" type="primary" @click="addPowerAction(scope.row)">权限设置</el-link>
              <el-link style="margin-left: 10px" type="danger" @click="deleteBtn(scope.row)">删除</el-link>
            </template>
          </el-table-column>
        </el-table>
          <div style="margin-top: 10px">
              <el-link style="margin-left: 10px" type="primary" @click="addRoleAction()" :disabled="multipleSelection.length <= 0">批量添加角色</el-link>
          </div>
        <div class="admin_table_main_pagination">
          <el-pagination
            @current-change="currentChange"
            @size-change="handleSizeChange"
            background
            :total="totalData"
            :page-size="pageSize"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            layout="sizes, prev, pager, next, total, jumper"
          ></el-pagination>
        </div>
      </div>
      <info :row="row" @dialog="getDialog" v-if="dialogDetailVisible"></info>
      <edit :row="row" @dialog="getDialog" v-if="dialogEditVisible"></edit>
      <role :users="selectedUser" :userIds="selectIds" :visible="addRoleDialogVisible" @dialog="getDialog"></role>
      <power :row="row" :visible="addPowerDialogVisible" @dialog="getDialog"></power>
    </div>
  </div>
</template>

<script>
import info from "@/components/admin/permission/user/check.vue";
import edit from "@/components/admin/permission/user/edit.vue";
import role from "@/components/admin/permission/user/role.vue";
import power from "@/components/admin/permission/user/power.vue";
export default {
  components: {
    info,
    edit,
    role,
    power,
  },
  props: {
    state: Number,
  },
  data() {
    return {
      list: [],
      date: "",
      row: {},
      loading: false,
      dialogDetailVisible: false,
      dialogEditVisible: false,
      addPowerDialogVisible: false,
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      rolesData: [],
      selectIds: [],
      where: {
        pageNum: 0,
        pageSize: 0,
        obj:{
            roleId:""
        }
      },
      addRoleDialogVisible: false,
      addPower: false,
      selectedUser:[],
        multipleSelection:[]
    };
  },
  methods: {
    listData() {
      this.loading = true;
      this.where.pageNum = this.currentPage;
      this.where.pageSize = this.pageSize;
      this.$post(this.$api.adminList, this.where).then((res) => {
        this.totalData = res.data.total;
        this.list = res.data.records;
        this.loading = false;
      });
    },
    handleSelectionChange(e) {
      this.multipleSelection = e;
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    orderDate(obj) {
      if (obj) {
        this.where.startDate = obj[0];
        this.where.endDate = obj[1];
      }
    },
    exportData() {
      // console.log("exportData");
    },
    getDialog(val) {
      this.dialogDetailVisible = val;
      this.dialogEditVisible = val;
      this.addRoleDialogVisible = val;
      this.addPowerDialogVisible = val;
    },
    showDailog(data) {
      this.row = data;
      this.dialogEditVisible = true;
    },
    deleteBtn(data) {
      this.row = data;
      this.dialogDetailVisible = true;
    },
    setState(data) {
      this.$post(this.$api.adminSet, { id: data.id, state: data.state }).then(
        (res) => {
          if (res.code === 1) {
            this.$message({
              type: "success",
              message: "成功!",
            });
          } else {
            this.$message({
              type: "error",
              message: "失败!",
            });
          }
        }
      );
    },
    addRoleAction(data){
        this.selectedUser = [];
        this.selectIds = [];
        if (data) {
            this.selectedUser.push({"value":data.id,"label":data.account});
            this.selectIds.push(data.id);
        }else {
            this.multipleSelection.forEach((v) => {
                this.selectedUser.push({"value":v.id,"label":v.account});
                this.selectIds.push(v.id);
            });
        }
      this.addRoleDialogVisible = true;
    },
    addPowerAction(data){
      this.row = data;
      this.addPowerDialogVisible = true;
    },
    roles() {
      this.$post(this.$api.roleList, {"obj":{}}).then((res) => {
        if (res.code === 1) {
          this.rolesData = res.data.records;
        }
      });
    },

  },
  created() {
    this.listData();
  },
};
</script>
<style lang="scss" scoped>
.endDate {
  color: #c0c4cc;
}
.el-image {
  height: 100px;
  width: 100px;
}
.el-select {
  width: 120px;
}
</style>
<style>
  .el-drawer__header span:focus {
    outline: 0!important;
  }
</style>
